<template>
  <section>
    <text-h2 class="text-center">Learn more about MRHS</text-h2>
    <section-container class="mt-12 flex justify-center">
      <div class="video-player aspect-video max-w-5xl">
    <video id="video-player" rel="video" class="video-player cursor-pointer" controls />
  </div>
    </section-container>
  </section>
</template>

<script setup>
const { initializePlayer } = useVideoPlayer();

onMounted(() => {
  initializePlayer("#video-player");
});
</script>

<style lang="scss">
.video-player {
  width: 100%;
  height: 100%;
}

:root {
  --plyr-font-smoothing: true;
}
.video__loader {
  background: var(--grey-300);
}
::v-deep(.plyr) {
  width: 100%;
  height: 100%;
}
</style>
